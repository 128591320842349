import React from "react";
import { AuthLayout } from '../components/AuthLayout';
import { ConfirmationSentForm } from '../components/ConfirmationSentForm';

export const ConfirmationSent = () => {
  return (
    <AuthLayout title="Confirmation Sent">
      <ConfirmationSentForm deactivated={true} />
    </AuthLayout>
  );
};
