import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';
import { useAuth } from 'lib/auth';

const getFrameworkAgreementsCount = (expired, companyID, userID) => {
  const queryString = qs.stringify({ expired });
  return axios.get(
    `/api/framework_agreements/count?${queryString}`,
    {headers: {
      "Company_id": companyID,
      "userID": userID,
    }}
  );
};

export const useFrameworkAgreementsCount = (expired = false, options = {}) => {
  const { user } = useAuth();
  return useQuery(
    `framework-agreements-expired-${expired}-count`,
    () => getFrameworkAgreementsCount(expired, user.Company.company_id, user.user_id),
    {
      //@ts-ignore
      initialData: { data: { count: 0 } },
      select: ({ data }) => data,
      ...options,
    }
  );
}