import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const updateFrameworkAgreement = ({
  frameworkAgreementId,
  faName,
  mainBuyerId,
  refNumber,
  description,
  startDate,
  endDate,
  promoted,
  moreInfoURL,
  tenderID,
  value,
}) =>
  axios.put(
    `/api/framework_agreements/${frameworkAgreementId}`,
    JSON.stringify({
      fa_name: faName,
      ref_number: refNumber,
      Description: description,
      main_buyer_id: mainBuyerId,
      start_date: startDate,
      end_date: endDate,
      promoted: promoted,
      more_info_url: moreInfoURL,
      tender_id: tenderID,
      value: value,
    })
  );

export const useUpdateFrameworkAgreement = (refetch) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: updateFrameworkAgreement,
    onSuccess: () => {
      enqueueSnackbar('Framework agreement was successfully updated', {
        variant: 'success',
      });

      refetch();
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
