import { axios } from "lib/axios"
import { auth } from "lib/firebase"
import { sendEmailVerification, createUserWithEmailAndPassword } from 'firebase/auth';
import { paths } from 'routes';
import { API_URL } from 'configuration';

export const register = async (data, newFirebaseUser = true) => {
  var userCredential;
  try {
    if (newFirebaseUser) {
      userCredential = await createUserWithEmailAndPassword(auth, data.companyEmail, data.password);
    }

    const account = await registerRequest(data);
    if (newFirebaseUser) {
      const accountID = account.data.account_id;
      const userID = account.data.users[0].user_id;
      const companyID = account.data.users[0].company_id;
      await localStorage.setItem("delegonHasUsers", "true");
      await localStorage.setItem("delegonUserID", userID);
      await localStorage.setItem("delegonCompanyID", companyID);
      const actionCodeSettings = {
        url: `${API_URL}${paths.auth.verifyEmail}?account_id=${accountID}&user_id=${userID}&company_id=${companyID}`,
      };
      await sendEmailVerification(userCredential?.user, actionCodeSettings);
    }
  } catch (error) {
    if (error.code === 'auth/email-already-in-use') {
      throw new Error("Account already exists.");
    } else if (error.code === 'auth/weak-password') {
      throw new Error("Password is too weak.");
    } else if (error.code === 'auth/invalid-email') {
      throw new Error("Invalid e-mail address");
    } else {
      throw error;
    }
  }
}

const registerRequest = async (data) => {
  const jsonData = {
    "account": {
      "account_id": data?.accountID,
      "email": data.companyEmail,
    },
    "user": {
      "first_name": data.firstName,
      "last_name": data.lastName,
      "phone_number": data.phoneNumber,
      "email": data.companyEmail,
      "role": "company_admin",
      "status": data?.status,
    },
    "company": {
      "company_id": data?.companyID,
      "name": data.companyName,
      "country": data.country,
      "vat": `SE${data.vat}01`,
      "status": data?.status,
    }
  }

  return await axios.post("/auth/register", JSON.stringify(jsonData));
}

