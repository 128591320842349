import MuiTextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { IconWrapper } from 'components/Icon';

export const RedMessageBar = ({ message, sx={} }) => {
  return (
    <MuiTextField
      name="warning"
      required={true}
      value={message}
      sx={{ mb: 0, ...sx}}
      fullWidth
      multiline={true}
      maxRows={2}
      color = "warning"
      disabled
      InputProps={{
        startAdornment: (
          <InputAdornment position="end">
            <IconWrapper icon="warning" sx={{height: 24, svg: {stroke: "#B82E46!important", strokeWidth:2}}} />
          </InputAdornment>
        ),
        sx: {
          p: 0,
          backgroundColor: "#F28296!important",
          height:60,
          boxShadow:25,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: "#FFF!important",
            disabled: true,
          }
        },
      }}
      inputProps={{
        sx:{
          padding: "0!important",
          pl: "12px!important",
          fontSize:"0.75rem",
          lineHeight: "150%",
          fontWeight: 600,     
        }
      }}
    />
  );
};