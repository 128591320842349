import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const updateTender = ({
  tenderID,
  tenderName,
  mainBuyerId,
  refNumber,
  description,
  moreInfoURL,
  postingDate,
  startDate,
  lastOfferDate,
  endDate,
  promoted,
  faID,
  value,
}) =>
  axios.put(
    `/api/tenders/${tenderID}`,
    JSON.stringify({
      tender_name: tenderName,
      ref_number: refNumber,
      Description: description,
      more_info_url: moreInfoURL,
      main_buyer_id: mainBuyerId,
      posting_date: postingDate,
      last_offer_date: lastOfferDate,
      start_date: startDate,
      end_date: endDate,
      promoted: promoted,
      fa_id: faID,
      value: value,
    })
  );

export const useUpdateTender = (refetch) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: updateTender,
    onSuccess: () => {
      enqueueSnackbar('Tender was succesfully updated', {
        variant: 'success',
      });

      refetch();
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
