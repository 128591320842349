import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { AccordionButton } from 'components/Button';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { ContentLayout } from 'components/Layout';

import { useQueryParams } from 'hook/useQueryParams';

import { paths } from 'routes';

import { useGetByID } from '../api/getById';
import { DetailViewTabList } from '../components/TabList';
import { DetailsTab } from '../components/DetailsTab';
import { BuyersTab } from '../components/BuyersTab';
import { SuppliersTab } from '../components/SuppliersTab';
import { EditForm } from '../components/EditForm';
import { useCompaniesCount } from '../api/getCompaniesCount';
import { useFilesCount } from '../api/getFilesCount';
import { StatusToggleButton } from '../components/StatusToggleButton';
import { useAuth } from 'lib/auth';
import { FilesTab } from '../components/FilesTab';
import { PremiumBanner } from '../../subscriptions/components/PremiumBanner';
import { Loader } from 'components/Loader';

export const DetailsView = () => {
  const queryParams = useQueryParams();

  const { account, user } = useAuth();
  let { id } = useParams();
  const [tab, setTab] = useState(queryParams?.tab || 'details');  
  const [ loading, setLoading ] = useState(false);
  const [timer, setTimer] = useState(null);

  const { data: dps, refetch: refetchDPS, error, isError, isLoading: dpsLoading, isFetched: isFetched1 } =
    useGetByID(id);
  const {
    //@ts-ignore
    data: { count: buyersCount, isLoading: buyersCountLoading, isFetched: isFetched2 },
  } = useCompaniesCount(id, 'buyer');
  // const {
  //   //@ts-ignore
  //   data: { count: suppliersCount },
  // } = useCompaniesCount(id, 'supplier');
  const {
    //@ts-ignore
    data: { count: filesCount, isLoading: filesCountLoading, isFetched: isFetched3 },
  } = useFilesCount(id);

  const switchTab = (_, newTab) => setTab(newTab);

  const isLoading = dpsLoading || buyersCountLoading || filesCountLoading;
  const isFetched = isFetched1 || isFetched2 || isFetched3;

  useEffect(() => {  
    if (isLoading) {
      const timeoutId = setTimeout(() => setLoading(true), 500);
      setTimer(timeoutId);
    } else {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isLoading]);

  if (isFetched) {    
    if (loading) {      
      setTimeout(() => setLoading(false), 1000);
    }
  }

  if (!loading && isError && error.message === "Object doesn't exist") {
    return <div>{error?.message}</div>;
  }

  return (
    <ContentLayout>
      {isLoading && !loading ? (
        <></>
      )
      : (
        loading ? (
          <Loader style={{marginTop: "40vh"}}/>
        )
        : (
          !loading && isError ? (
            <PremiumBanner message={`This DPS is only available for Premium members. Click here for more information`} handleSuccess={refetchDPS} currentLevel={user.Company.subscription_level} />
          ) : (
            <Stack height="100%">
              <BreadCrumbs
                items={[
                  { name: '', to: paths.dps },
                  { name: 'Dynamic Purchasing Systems', to: paths.dps },
                  { name: `${dps.MainBuyer.Name}`, to: `${paths.buyersDetailsView(dps.MainBuyer.company_id)}?tab=dps` },
                  { name: dps?.dps_name ?? 'Details View' },
                ]}
              />
              <Box
                height={50}
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  paddingBottom: 1.5,
                  paddingTop: 1,
                }}
              >
                <Stack pl={4}>
                  <Typography variant="h3">
                    <Stack flex={1} flexDirection={"row"} gap={1}>
                      {dps?.dps_name || ''}
                      {account.super_admin && (
                        <Stack flex={1} sx={{justifyContent: "center"}}>
                          <Box
                            borderRadius="100%"
                            width={8}
                            height={8}
                            border="1px solid"
                            borderColor={dps?.Status === 'active' ? 'success.light' : 'error.light'}
                            bgcolor={dps?.Status === 'active' ? 'success.light' : 'error.light'}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </Typography>
                  <Typography variant='caption' fontWeight={600}>
                    {`${dps.MainBuyer.Name} (${dps.ref_number})`}
                  </Typography>
                </Stack>

                {account.super_admin && (
                  <AccordionButton label="Actions" sx={{ width: 130, zIndex: 10 }}>
                    <Stack gap={0.5}>
                      <EditForm dps={dps} refetch={refetchDPS} />

                      <StatusToggleButton
                        refetch={refetchDPS}
                        status={dps?.Status}
                      />
                    </Stack>
                  </AccordionButton>
                )}
              </Box>

              <DetailViewTabList
                value={tab}
                onChange={switchTab}
                tabs={[
                  { label: 'Details' },
                  { label: 'Buyers', count: buyersCount },
                  // { label: 'Suppliers', count: suppliersCount },
                  { label: 'Files', count: filesCount },
                ]}
              />

              <Stack
                px={4}
                py={2}
                bgcolor="background.paper"
                borderTop="1px solid"
                borderColor="border.main"
                gap={1}
                height="100%"
              >
                {error && (
                  <PremiumBanner message={`This DPS is only available for Premium members. Click here for more information`} handleSuccess={refetchDPS} currentLevel={user.Company.subscription_level} />
                )}
                {!error && (
                  <>
                    <DetailsTab currentTab={tab} dps={dps} />
                    <BuyersTab
                      currentTab={tab}
                    />
                    {/* <SuppliersTab currentTab={tab} /> */}
                    <FilesTab currentTab={tab} />
                  </>
                )}
              </Stack>
            </Stack>
          )
        )
      )}
    </ContentLayout>
  );
};
