import React from 'react';
import { Stack, Tab, Tabs } from '@mui/material';

export const DetailViewTabList = ({ value, onChange, tabs, multi = false }) => {
  if (!multi) {
    return (
      <Stack
        boxSizing="border-box"
        direction="row"
        gap={2}
        position="relative"
        minHeight={34}
        maxHeight={34}
        width={1}
        backgroundColor="white"
      >
        <Tabs
          value={value}
          onChange={onChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            width: { xs: 1, md: 'auto' },
            position: 'absolute',
            left: { sm: '-8px', md: 0 },
            top: 0,
            px: { md: 4 },
            '.MuiTabs-indicator': { display: 'none' },
            minHeight: 0,
            height: 35,
            maxHeight: 35,
          }}
        >
          {tabs.map(({ label, count, value }) => (
            <Tab
              key={label}
              label={label + (count === undefined ? '' : ` (${count})`)}
              value={value ? value : label.toLowerCase()}
              sx={({ palette, spacing, shadows }) => ({
                boxSizing: 'border-box !important',
                minHeight: 35,
                maxHeight: 35,
                boxShadow: shadows[25],
                display: 'block',
                pt: 1,
                pb: 0,
                px: 2,
                fontSize: '0.75rem',
                fontWeight: 700,
                lineHeight: 1.5,
                letterSpacing: '-1%',
                borderRadius: `${spacing(2)} ${spacing(2)} 0 0 `,
                bgcolor: palette.background.paper,
                borderBottom: `1px solid ${palette.grey[200]}`,

                '&:not(:last-child)': { mr: 0.5 },
                '&.Mui-selected': {
                  boxShadow: 'none',
                  border: `1.5px solid ${palette.grey[200]}`,
                  borderBottom: '3px solid white !important',
                  background: `linear-gradient(154.16deg, #5ED2F9 -199.15%, #FFFFFF 56.33%)`,
                },
              })}
            />
          ))}
        </Tabs>
      </Stack>
    )
  }

  return (
    <Stack
      direction="row"
      gap={2}
      position="relative"
      minHeight={34}
      sx={{
        flexWrap: 'wrap',
        '& .MuiTabs-root': {
          width: '100%', // Let the Tabs take full width of the container
        },
      }}
    >
      <Tabs
      spacing={5}
        value={value}
        onChange={onChange}
        aria-label="Multi row tabs"
        sx={{
          // position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          '.MuiTabs-indicator': { display: 'none' },
          '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap',
          },
        }}
      >
        {tabs.map(({ label, count, value }) => (
          <Tab
            key={value ? value : label}
            label={label + (count === undefined ? '' : ` (${count})`)}
            value={value ? value : label.toLowerCase()}
            sx={({ palette, spacing, shadows }) => ({
              boxSizing: 'border-box !important',
              minHeight: 35,
              maxHeight: 35,
              boxShadow: 'none',
              display: 'block',
              pt: 1,
              pb: 0,
              px: 2,
              fontSize: '0.75rem',
              fontWeight: 700,
              lineHeight: 1.5,
              letterSpacing: '-1%',
              borderRadius: `${spacing(2)} ${spacing(2)} 0 0`,
              bgcolor: palette.background.paper,
              border: `1.5px solid ${palette.grey[200]}`,
              borderBottom: `3px solid white !important`,
              '&:not(:last-child)': { mr: 0.5, mb: 0.5 },
              '&.Mui-selected': {
                boxShadow: 'none',
                border: `1.5px solid ${palette.grey[200]}`,
                borderBottom: '3px solid white !important',
                background: `linear-gradient(154.16deg, #5ED2F9 -199.15%, #FFFFFF 56.33%)`,
              },
            })}
          />
        ))}
      </Tabs>
    </Stack>
  )
};
