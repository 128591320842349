import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { useTenderSuppliers } from '../api/getTenderSuppliers';
import { useRemoveCompany } from '../api/removeCompany';

import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { SupplierListItem } from './SupplierListItem';
import { NotFound } from 'components/NotFound/NotFound';
import { useDebouncedCallback } from 'use-debounce';
import { Search } from 'components/Search';
import { Pagination } from 'components/Pagination';
import { ConsortiumGroupListItem } from './ConsortiumGroupListItem';
import { useRemoveConsortiumGroup } from '../api/removeConsortiumGroup';
import { AddSuppliersForm } from './AddSuppliersForm';
import { useTenderCompanyCount } from '../api/getTendersCompanyCount';
import { useAuth } from 'lib/auth';
import { Loader } from 'components/Loader';

const suppliersTabName = 'bidders';

export const SuppliersTab = ({ currentTab, refetchSuppliersFiles }) => {
  const { account } = useAuth();
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [markedForDelete, setMarkedForDelete] = useState(null);
  const [includeInactive, setIncludeInactive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null);

  const {
    data: { suppliers, total },
    refetch: refetchSuppliers,
    isFetching,
  } = useTenderSuppliers(id, {
    search,
    includeInactive,
    page: page - 1,
    queryOptions: { 
      onSettled: () => {
        clearTimeout(timer);
        setTimeout(() => setLoading(false), 1000);
      },
      enabled: currentTab === suppliersTabName
    },
  });

  const {
    //@ts-ignore
    refetch: refetchSuppliersCount,
  } = useTenderCompanyCount(id, 'supplier');

  const handlePageChange = (_, page) => setPage(page);
  const handleIncludeInactiveChange = () => setIncludeInactive(!includeInactive);
  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    debouncedSearchChange(search);
  };

  const onSupplierRemoval = () => {
    refetchSuppliers();
    setMarkedForDelete(null);
    refetchSuppliersCount();
    refetchSuppliersFiles();
  };
  const onSuppliersAdd = () => {
    refetchSuppliers();
    refetchSuppliersCount();
    refetchSuppliersFiles();
  };
  const { mutateAsync: removeCompany } = useRemoveCompany('supplier', onSupplierRemoval);
  const { mutateAsync: removeConsortiumGroup } = useRemoveConsortiumGroup(onSupplierRemoval);

  useTabReset();
  useRefetch();

  useEffect(() => {
    if (isFetching) {
      const timeoutId = setTimeout(() => setLoading(true), 500);
      setTimer(timeoutId);
    } else {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isFetching]);

  if (currentTab !== suppliersTabName) return null;

  return (
    <>
      {account.super_admin && (
        <DeleteConfirmationModal
          title={markedForDelete?.type === 'supplier' ? 'Remove Bidder' : 'Remove Consortium Group'}
          markedForDelete={markedForDelete}
          mutateAsync={() =>
            markedForDelete?.type === 'supplier'
              ? removeCompany({ tenderID: id, tcID: markedForDelete?.itemId })
              : removeConsortiumGroup({ tenderID: id, cgId: markedForDelete?.itemId })
          }
        >
          <Typography variant="body1" fontWeight={500}>
            Please confirm the removal of the{' '}
            {markedForDelete?.type === 'supplier' ? 'company ' : 'consortium group '}
            <span style={{ fontWeight: 'bold' }}>{markedForDelete?.name}.</span>
          </Typography>
        </DeleteConfirmationModal>
      )}

      <Stack gap={2} height={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} width={1}>
          <Search handleSearchChange={handleSearchChange} sx={{minWidth: 200, width: 350}} />
          {account.super_admin && (
            <>
            <FormGroup sx={{ flex: '1 1 300px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={includeInactive}
                    onChange={handleIncludeInactiveChange}
                  />
                }
                label="Include inactive"
              />
            </FormGroup>
            <Box sx={{minWidth: 150, width: 200}}>
              <AddSuppliersForm refetch={onSuppliersAdd} alreadyAdded={suppliers} />
            </Box>
            </>
          )}
        </Stack>

        <Divider />

        {isFetching && !loading ? 
        (
          <></>
        ) :
        (!isFetching || (isFetching && loading)) ?
        (
          <>
          {loading && (
            <Loader />
          )}

          {!loading && (
            <>
            <Stack gap={1} flex={1}>
              {suppliers?.length === 0 && (
                <NotFound
                  title="No Bidders found"
                  caption={
                    search
                      ? 'Please try another search term'
                      : account.super_admin ? 'Click the "Add bidder" button to begin' : ''
                  }
                  wrapperSx={{ mt: 19 }}
                />
              )}
              {suppliers?.length !== 0 &&
                suppliers.map(
                  ({ Company: supplier, ConsortiumGroup: cg, tc_id, company_type }, index) =>
                    company_type === 'supplier' ? (
                      <SupplierListItem
                        key={supplier.Name + index}
                        markForDelete={() =>
                          setMarkedForDelete({
                            name: supplier.Name,
                            itemId: tc_id,
                            type: 'supplier',
                          })
                        }
                        tender_id = {id}
                        tcID = {tc_id}
                        {...supplier}
                      />
                    ) : (
                      <ConsortiumGroupListItem
                        key={cg.cg_name + index}
                        tender_id={id}
                        supplierList={[]}
                        markForDelete={() =>
                          setMarkedForDelete({
                            name: cg.cg_name,
                            itemId: cg.cg_id,
                            type: 'consortium',
                          })
                        }
                        tcID = {tc_id}
                        {...cg}
                      />
                    )
                )}
            </Stack>

            <Stack direction="row" justifyContent="center">
              <Pagination page={page} handlePageChange={handlePageChange} itemCount={total} />
            </Stack>
            </>
          )}
        </>
        ) :
        (
          <></>
        )}
      </Stack>
    </>
  );

  function useRefetch() {
    useEffect(() => {
      refetchSuppliers();
      refetchSuppliersFiles()
    }, [page, includeInactive, search]);
  }

  function useTabReset() {
    useEffect(() => {
      if (currentTab !== suppliersTabName) {
        setPage(1);
        setSearch('');
        setIncludeInactive(false);
      }
    }, [currentTab]);
  }
};
