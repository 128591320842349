import React, { Route, Routes } from 'react-router-dom';

import { SUB_PATHS } from 'routes';

import { Login } from './Login';
import { Register } from './Register';
import { BuyersRegister } from './BuyersRegister';
import { ForgotPassword } from './ForgotPassword';
import { AcceptInvitation } from './AcceptInvitation';
import { AccessRequested } from '../components/AccessRequested';
import { Action } from './Action';
import { ConfirmationSent } from './ConfirmationSent';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path={SUB_PATHS.auth.login} element={<Login />} />
      <Route path={SUB_PATHS.auth.register} element={<Register />} />
      <Route path={SUB_PATHS.auth.buyersRegister} element={<BuyersRegister />} />
      <Route path={SUB_PATHS.auth.forgotPassword} element={<ForgotPassword />} />
      <Route path={SUB_PATHS.auth.action} element={<Action />} />
      <Route path={SUB_PATHS.auth.acceptInvitation} element={<AcceptInvitation />} />
      <Route path={SUB_PATHS.auth.accessRequested} element={<AccessRequested />} />
      <Route path={SUB_PATHS.auth.confirmationSent} element={<ConfirmationSent />} />
    </Routes>
  );
};
