import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';

const getCompanyFrameworkAgreementFilesCount = async (faId, companyID) => {
  const {
    data: { facID },
  } = await axios.get(`/api/companies/${companyID}/framework_agreements/${faId}`);

  return { facID };
};


export const useCompanyFacID = (faId, companyID, queryParams = {}) => {
  return useQuery(
    `company-${companyID}-agreement-${faId}-facID`,
    () => getCompanyFrameworkAgreementFilesCount(faId, companyID),
    {
      //@ts-ignore
      select: ({ facID }) => ({ facID }),
      initialData: { facID: 0 },
      ...queryParams,
    }
  );
}