import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography, Divider, Checkbox, FormControlLabel, FormGroup, InputAdornment } from '@mui/material';

import { PaperCard } from 'components/Card';
import { FormProvider, TextField, DateInput, OnLoadSelect, NumericFormat } from 'components/Form';
import { useForm } from 'react-hook-form';
import { AccordionListItem } from 'components/Button';
import { useParams } from 'react-router-dom';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { additionalDetailsSchema } from '../validation';
import { useUpdateFrameworkAgreement } from '../api/updateFrameworkAgreement';
import { useFrameworkAgreementTenders } from '../api/getFrameworkAgreementTenders';

const getDefaultValues = () => ({
  faName: '',
  description: '',
  promoted: false,
  startDate: null,
  endDate: null,
  moreInfoURL: '',
  tenderID: null,
  value: null,
});

const ItemCard = ({ tender_name, rest }) => {
  return (
    <Typography variant="body1" fontWeight="bold">
      {tender_name}
    </Typography>
)};

export const EditForm = ({frameworkAgreement, refetch}) => { 
  let { id } = useParams();

  const [open, setOpen] = useState(false);
  const [ promoted, setPromoted] = useState(frameworkAgreement?.promoted)
  const [ tenderID, setTenderID] = useState(frameworkAgreement?.tender_id)
  const handlePromotedChange = () => {
    const newValue = !promoted;
    setPromoted(newValue);
    methods.setValue("promoted", newValue);
  }

  const handleTenderChange = (value) => {
    setTenderID(value?.tender_id);
    methods.setValue("tenderID", value?.tender_id);
  }

  const methods = useForm({
    defaultValues: getDefaultValues(),
    resolver: useYupValidationResolver(additionalDetailsSchema),
  });

  const { mutateAsync } = useUpdateFrameworkAgreement(refetch);

  const handleClose = () => {
    setOpen(false);
    methods.reset({ ...getDefaultValues() });
  };

  const onSubmit = (values) => {
    mutateAsync({
      ...values,
      frameworkAgreementId: id,
    }).then(handleClose);
  };

  useEffect(() => {
    if (!open) {
      methods.reset({ ...frameworkAgreement });
      document.body.style.overflow = '';
    } else if (open) {
      const {
        fa_name,
        Description,
        more_info_url,
        end_date,
        main_buyer_id,
        start_date,
        value,
      } = frameworkAgreement;

      methods.reset({
        faName: fa_name,
        mainBuyerId: main_buyer_id,
        description: Description,
        moreInfoURL: more_info_url,
        promoted: promoted,
        startDate: new Date(start_date),
        endDate: new Date(end_date),
        tenderID: tenderID,
        value: value,
      });
    
      setPromoted(frameworkAgreement.promoted);
      setTenderID(frameworkAgreement?.tender_id);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [open]);

  const {
    data: { tenders },
  } = useFrameworkAgreementTenders(id, {
    mainBuyerID: frameworkAgreement?.main_buyer_id,
    queryOptions: { 
      enabled: open },
  });

  return (
    <>
      <AccordionListItem onClick={() => setOpen(true)}>Edit</AccordionListItem>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          background: '#F3F4F6',
          justifyContent: 'center',
          display: open ? 'flex' : 'none',
          zIndex: 40,
          alignItems: 'flex-start',
          overflow: 'hidden',
        }}
      >
        <Stack
          sx={{
            position: 'relative',
            width: '100%',
            height: `calc(100% - 8 * 26.25px)`,
            marginTop: 26.25,
            overflowY: 'auto',
            backgroundColor: '#fff',
          }}
        >
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <PaperCard
              height={69}
              sx={{
                background: '#fff',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                px: 4,
                py: 2,
                border: 0,
                borderRadius: 0,
                boxShadow: 26,
                display: 'flex',
                boxSizing: 'border-box',
              }}
            >
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography>Editing ‘{frameworkAgreement?.fa_name}’</Typography>
                <Box
                  sx={{
                    width: 165,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    size="medium"
                    onClick={() => setOpen(false)}
                    variant="contained"
                    sx={({ palette }) => ({
                      width: 82,
                      boxShadow: 25,
                      color: palette.primary.main,
                      backgroundColor: '#fff',
                    })}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="medium"
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ width: 67 }}
                  >
                    Save
                  </Button>
                </Box>
              </Stack>
            </PaperCard>

            <Stack
              direction="row"
              width="100%"
              minHeight={`calc(100vh - 8 * 26.25px)`}
              height="100%"
              bgcolor={'#fff'}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Stack width={'50%'} direction="column" px={8} py={4}>
                <TextField
                  name="description"
                  label="Description"
                  placeholder="Please enter description"
                  type="text"
                  fullWidth
                  sx={{
                    mb: 2,
                    '& .MuiInputBase-root, .MuiInputBase-input': {
                      p: 0,
                    },
                  }}
                  multiline
                  minRows={2}
                />
              </Stack>

              <Stack
                width={'50%'}
                direction="column"
                px={8}
                py={4}
                gap={2}
              >
                <FormGroup sx={{ 
                  flex: '1 1 50',
                  alignItems:"baseline",
                  '& .MuiFormControlLabel-root': {
                    m:0
                  },
                  '& .MuiFormControlLabel-label': {
                    fontSize:"0.75rem"
                  },
                }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={promoted}
                        onChange={handlePromotedChange}
                        name="promoted"
                      />
                    }
                    label="Promoted"
                    labelPlacement="top"
                  />
                </FormGroup>
                <TextField
                  name="faName"
                  label="Framework Agreement Name"
                  placeholder="Please enter Framework agreement name"
                  required={true}
                  type="text"
                  fullWidth
                />
                <NumericFormat
                  name="value"
                  label="Value"
                  placeholder="Please enter value"
                  type="input"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                      >
                        MSEK
                      </InputAdornment>
                    )
                  }}
                />
                <OnLoadSelect
                  label={"Related Tender"}
                  fullWidth
                  onChange={handleTenderChange}
                  ItemCard={(props) => <ItemCard {...props} />}
                  options={tenders}
                  value={frameworkAgreement?.Tender}
                  search_key='tender_name'
                  unique_key='tender_id'
                />
                <TextField
                  name="moreInfoURL"
                  label="More Information URL"
                  placeholder="Please enter URL with more information"
                  required={false}
                  type="text"
                  sx={{ mb: 2 }}
                  fullWidth
                />
                <Stack
                  width={'100%'}
                  direction="row"
                  justifyContent="space-between"
                  gap={2}
                >
                  <DateInput
                    name="startDate"
                    label="Start Date"
                    required
                    wrapperSx={{ mb: 2 }}
                    fullWidth
                  />
                  <DateInput
                    name="endDate"
                    label="End Date"
                    required
                    wrapperSx={{ mb: 2 }}
                    fullWidth
                  />
                </Stack>
              </Stack>
            </Stack>
          </FormProvider>
        </Stack>
      </Box>
    </>
  );
};
