import { auth } from "lib/firebase"
import { sendEmailVerification } from 'firebase/auth';
import { paths } from 'routes';
import { API_URL } from 'configuration';

export const sendVerificationEmail = async (data) => {
  try {
    const accountID = data.account_id;
    const userID = data.user_id;
    const companyID = data.company_id;
    const actionCodeSettings = {
      url: `${API_URL}${paths.auth.verifyEmail}?account_id=${accountID}&user_id=${userID}&company_id=${companyID}`,
    };

   // await sendEmailVerification(auth?.currentUser, actionCodeSettings);
    if (auth?.currentUser) {
      await sendEmailVerification(auth.currentUser, actionCodeSettings)
        .then(() => {
          console.log('Verification email sent successfully.');
        })
        .catch((error) => {
          console.error('Error sending verification email:', error);
        });
    } else {
      console.error('User is not authenticated.');
    }
  } catch (error) {
    throw error;
  }
}
