import React, { useState } from 'react';
import { Autocomplete, CircularProgress, TextField, Stack } from '@mui/material';
import { InputWrapper } from './InputWrapper';

export const OnLoadSelect = ({
  value = null,
  label = '',
  fullWidth = false,
  wrapperSx = {},
  onChange,
  ItemCard,
  options = [],
  loading = false,
  noOptionsText = 'No options available',
  search_key = '',
  unique_key = '',
}) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(value);

  const enhancedOptions = [
    { [unique_key]: null, [search_key]: 'None' },
    ...options,
  ];

  return (
    <InputWrapper fullWidth={fullWidth} wrapperSx={wrapperSx} label={label}>
      <Autocomplete
        fullWidth
        value={selectedValue}
        isOptionEqualToValue={(option, value) => value && option[search_key] === value[search_key]}
        blurOnSelect={true}
        options={enhancedOptions}
        getOptionLabel={(item) => item[search_key] ? item[search_key] : ''}
        onChange={(event, newValue) => {
          event.preventDefault();
          setSelectedValue(newValue);
          onChange && onChange(newValue);
        }}
        // open={inputValue.length > 0}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        noOptionsText={noOptionsText}
        renderOption={(props, option) => (
          <Stack gap={2} alignItems={'flex-start !important'} {...props} key={option[unique_key]} >
            <ItemCard {...option} />
          </Stack>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // Disable browser autofill
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="primary" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={{
              '*': { fontWeight: 'bold !important' },
              '.MuiInputBase-root': { backgroundColor: 'white', py: 0 },
            }}
            placeholder="Please enter a search parameter"
          />
        )}
        ListboxProps={{
          sx: {
            p: 0,
            boxShadow: 25,
            borderWidth: 2,
            maxHeight: 300,
            '& > *': {
              p: '16px !important',
              bgcolor: 'white !important',
            },
            '& > *:hover': { bgcolor: 'white !important' },
            '& > :not(:last-child)': {
              borderBottom: '2px solid',
              borderColor: 'border.main',
            },
          },
        }}
      />
    </InputWrapper>
  );
};
