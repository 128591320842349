import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';
import { useAuth } from 'lib/auth';

const getCompanyUsersCount = async (companyID, userID) => {
  return await axios.get(
    `/api/companies/${companyID}/contacts/count`, {
      headers: {
        "Company_id": companyID,
        "userID": userID
      }
    }
  );
};

export const useCompanyUsersCount = (companyID, options = {}) => {
  const { user } = useAuth();

  return useQuery(
    `company-${companyID}-users-count`,
    () => getCompanyUsersCount(companyID, user?.user_id),
    {
      //@ts-ignore
      initialData: { data: { count: 0 } },
      select: ({ data }) => data,
      ...options,
    }
  );
}