import { useEffect, useState } from 'react';
import Axios from 'axios';
import { API_URL } from 'configuration';
import { auth } from 'lib/firebase';
import { useAuth } from 'lib/auth';

const axios = Axios.create({
  baseURL: API_URL,
});

const authRequestInterceptor = async (config) => {
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken();
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  const userID = JSON.parse(localStorage.getItem("delegonUserID"));
  const companyID = JSON.parse(localStorage.getItem("delegonCompanyID"));
  config.headers['userID'] = userID === null ? config.headers['userID'] : userID;
  config.headers['Company_id'] = companyID === null ? config.headers['Company_id'] : companyID;

  return config;
};

axios.interceptors.request.use(authRequestInterceptor);

const axiosAuth = Axios.create({
  baseURL: API_URL,
});

const axiosFiles = Axios.create();

const useAxios = (method, url, payload = null) => {
  const { user } = useAuth();
  const [data, setData] = useState(null);
  useEffect(() => {
    (async () => {
      const header = {headers: {"company_id": user.Company.company_id}}
      let response = {};
      switch (method) {
        case "GET":
          response = await axiosGet(url, header)
        break;
        case "POST":
          response = await axiosPost(url, payload, header)
        break;
      
        default:
          response = await axiosGet(url)
        break;
      }

      setData(response?.data)
    })();

    return data;
  }, []);

}

const axiosGet = (url, header) => {
  return axios.get(url, header)
}

const axiosPost = (url, payload, header) => {
  return axios.post(url, payload, header)
}

export { axios, axiosAuth, axiosFiles, useAxios};
