import React from 'react';
import { Stack } from '@mui/material';

const defaultStyling = {
  '& > *:not(:last-child)': {
    borderRight: '2px solid',
    borderColor: 'border.main',
  },
};

export const ListCard = ({ sx = {}, children, ...rest }) => {
  return (
    <Stack
      direction="row"
      gap={1}
      p={2}
      px={1}
      borderRadius={2}
      border="1px solid"
      borderColor="border.main"
      boxShadow={25}
      bgcolor="background.paper"
      sx={
        typeof sx === 'function'
          ? (theme) => ({
              ...defaultStyling,
              ...sx(theme),
            })
          : { ...defaultStyling, ...sx }
      }
      {...rest}
    >
      {children}
    </Stack>
  );
};
