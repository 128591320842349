import React from 'react';
import { Box, Stack, Typography, Tooltip } from '@mui/material';


export const Status = ({ status }) => {
  return (
    <Stack justifyContent="center" /* flex={0.3} */>
      {/* <Typography variant="caption" fontWeight={700}>
        Status
      </Typography> */}
      <Tooltip
        arrow
        title={`${status}`}
        placement="top"
        slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -14] } }] } }}
      >
        <Stack direction="row" alignItems="center" gap={1} height={0.5}>
          <Box
            borderRadius="100%"
            width={8}
            height={8}
            border="1px solid"
            borderColor={status === 'active' ? 'success.light' : 'error.light'}
            bgcolor={status === 'active' ? 'success.light' : 'error.light'}
          />
        </Stack>
      </Tooltip>
    </Stack>
  );
};
