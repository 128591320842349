import React, { useState } from 'react';
import { Box, Button, Stack, Typography, Divider, Checkbox, FormControlLabel, FormGroup, InputAdornment } from '@mui/material';

import { PaperCard } from 'components/Card';
import { FormProvider, TextField, DateInput, NumericFormat } from 'components/Form';

export const AdditionalDetails = ({ handleCloseModal, methods, onSubmit }) => {
  const [ promoted, setPromoted] = useState(false)
  const handlePromotedChange = () => {
    const newValue = !promoted;
    setPromoted(newValue);
    methods.setValue("promoted", newValue);
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        background: '#F3F4F6',
        justifyContent: 'center',
        display: 'flex',
        zIndex: 40,
        alignItems: 'flex-start',
        overflow: 'hidden',
      }}
    >
      <Stack
        sx={{
          position: 'relative',
          width: '100%',
          height: `calc(100% - 8 * 26.25px)`,
          marginTop: 26.25,
          overflowY: 'auto',
          backgroundColor: '#fff',
        }}
      >
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <PaperCard
            height={69}
            sx={{
              background: '#fff',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              px: 4,
              py: 2,
              border: 0,
              borderRadius: 0,
              boxShadow: 26,
              display: 'flex',
              boxSizing: 'border-box',
            }}
          >
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography>Adding ‘{methods.getValues('dpsName')}’</Typography>
              <Box
                sx={{
                  width: 165,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  size="medium"
                  onClick={handleCloseModal}
                  variant="contained"
                  sx={({ palette }) => ({
                    width: 82,
                    boxShadow: 25,
                    color: palette.primary.main,
                    backgroundColor: '#fff',
                  })}
                >
                  Cancel
                </Button>
                <Button
                  size="medium"
                  type="submit"
                  // onClick={onSubmit}
                  variant="contained"
                  color="primary"
                  sx={{ width: 67 }}
                >
                  Save
                </Button>
              </Box>
            </Stack>
          </PaperCard>

          <Stack
            direction="row"
            width="100%"
            minHeight={`calc(100vh - 8 * 26.25px)`}
            height="100%"
            bgcolor={'#fff'}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Stack width={'50%'} direction="column" px={8} py={4}>
              <TextField
                name="description"
                label="Description"
                placeholder="Please enter description"
                type="text"
                fullWidth
                sx={{
                  mb: 2,
                  '& .MuiInputBase-root, .MuiInputBase-input': {
                    p: 0,
                  },
                }}
                multiline
                minRows={2}
              />              
            </Stack>

            <Stack width={'50%'} direction="column" px={8} py={4}>
              <FormGroup sx={{ 
                  flex: '1 1 50',
                  alignItems:"baseline",
                  '& .MuiFormControlLabel-root': {
                    m:0
                  },
                  '& .MuiFormControlLabel-label': {
                    fontSize:"0.75rem"
                  },
                }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={promoted}
                      onChange={handlePromotedChange}
                      name="promoted"
                    />
                  }
                  label="Promoted"
                  labelPlacement="top"
                />
              </FormGroup>
              <TextField
                name="dpsName"
                label="DPS Name"
                placeholder="Please enter DPS name"
                required
                type="text"
                sx={{ mb: 2 }}
                fullWidth
              />
              <NumericFormat
                name="value"
                label="Value"
                placeholder="Please enter value"
                type="input"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                    >
                      MSEK
                    </InputAdornment>
                  )
                }}
                sx={{ mb: 2 }}
              />
              <TextField
                name="moreInfoURL"
                label="More Information URL"
                placeholder="Please enter Link to external website"
                required
                type="text"
                sx={{ mb: 2 }}
                fullWidth
              />
              <Stack
                width={'100%'}
                direction="column"
                gap={2}
              >
                <Stack
                  width={'49%'}
                  direction="row"
                  gap={2}
                >
                  <DateInput
                    name="postingDate"
                    label="Posting Date"
                    required
                    fullWidth
                  />
                </Stack>
                <Stack
                  width={'100%'}
                  direction="row"
                  gap={2}
                >
                  <DateInput
                    name="startDate"
                    label="Start Date"
                    required
                    fullWidth
                  />
                  <DateInput
                    name="endDate"
                    label="End Date"
                    required
                    fullWidth
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </FormProvider>
      </Stack>
    </Box>
  );
};
