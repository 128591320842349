import React, { useState, useEffect, useRef } from 'react';
import { Button, Stack, Typography } from '@mui/material';

import { PaperCard } from 'components/Card';
import { paths } from 'routes';
import { useAuth } from 'lib/auth';
import { RedMessageBar } from 'components/RedMessageBar';
import { FormProvider, TextField } from 'components/Form';
import { useForm } from 'react-hook-form';
import { auth } from "lib/firebase"
import { signInWithEmailAndPassword } from 'firebase/auth';
import { ConfirmationSentForm } from './ConfirmationSentForm';

export const VerifyEmailForm = ({
  onSuccess,
  actionCode,
  apiKey,
  continueURL
}) => {
  const { activate, verifyActionCode, sendverificationemail, firebaseUser, logout } = useAuth();
  const [loading, setLoading] = useState(true);
  const [ error, setError ] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [values, setValues] = useState(null);
  const initialLoad = useRef(true);

  const url = new URL(continueURL);
  const firebaseUID = url.searchParams.get('firebase_uid');
  const accountID = url.searchParams.get('account_id');
  const userID = url.searchParams.get('user_id');
  const companyID = url.searchParams.get('company_id');
  
  useEffect(() => {
    const verifyCode = async () => {
      try {
        await verifyActionCode(actionCode);
        await activateProfile();
      } catch (error) {
        setError(error.message);
      } finally {
        logout();
        setLoading(false);
      }
    }

    const activateProfile = async () => {
      await activate(firebaseUID, accountID, userID, companyID);
    }

    if (initialLoad.current) {
      initialLoad.current = false;
      verifyCode();
    }
  }, []);

  const resendVerificationEmail = async () => {
    setLoading(true);
    setError('');
    try {
      const data = {
        "account_id": accountID,
        "user_id": userID,
        "company_id": companyID,
      }
      setValues(data);

      if (firebaseUser) {
        await sendverificationemail(data);
        setEmailSent(true);
      } else {
        setValues({...values, companyEmail:email})
        await signInWithEmailAndPassword(auth, email, password);
        await sendverificationemail(data);
        setEmailSent(true);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      logout();
      setLoading(false);
    }
  }

  const methods = useForm();

  return (
    <>
    {!loading && (
      <>
      {emailSent ? (
        <ConfirmationSentForm
          values={ values }
          initLoad={false}
        />
      ) :
        <PaperCard width={368} maxWidth={368} sx={{mb:5}}>
          <Stack>
            {error && (
              <>
                <Typography variant="h5" sx={{ mb: 3 }}>
                  Verify Email
                </Typography>
                <RedMessageBar message={error} sx={{mb:2}} />
                {!firebaseUser && (
                  <FormProvider methods={methods} onSubmit={(e) => e.preventDefault()}>
                    <Stack>
                      <TextField
                        name="companyEmail"
                        label="Company email"
                        placeholder="Please enter your email"
                        required={true}
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ mb: 2 }}
                        fullWidth
                      />
                      <TextField
                        name="password"
                        label="Password"
                        placeholder="Please enter your password"
                        required={true}
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={Boolean(methods?.formState?.errors?.password)}
                        helperText={methods?.formState?.errors?.password?.message}
                        sx={{ mb: 0.5 }}
                        fullWidth
                      />
                      <Stack direction={"row"} width={1} justifyContent={"space-between"}>
                        <Button size="medium" variant="contained" color="primary" sx={{ mt: 2, mb: 0.5 }} onClick={resendVerificationEmail}>
                          Login and resend
                        </Button>
                        <Button size="medium" variant="contained" color="secondary" sx={{ mt: 2, mb: 0.5 }} href={paths.auth.login}>
                          Return to home
                        </Button>
                      </Stack>
                    </Stack>
                  </FormProvider>
                )}
              </>
            )}
            {!error && (
              <>
                <Typography variant="h5" sx={{ mb: 3 }}>
                  Your email has been verified
                </Typography>
                <Stack>
                  <Typography variant="body1">
                    You can now sign in with your new account
                  </Typography>

                  <Button size="large" variant="contained" color="primary" sx={{ mt: 2, mb: 0.5 }} href={paths.homepage}>
                    Proceed to login page
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
        </PaperCard>
      }
      </>
    )}
    </>
  );
};
