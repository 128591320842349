import React, { useState, useEffect} from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { EXTERNAL } from 'routes';
import { FormProvider, TextField, Select } from 'components/Form';
import { Modal } from 'components/Modal';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { subscriptionSchema } from '../validation';

import { useCompanyUsersCount } from '../../companies/api/getCompanyUsersCount';

import { useAuth } from 'lib/auth';
import { useSubscribe } from '../api/subscribe';

const subscriptionLevelsSuppliers = [
  {
    label: 'Silver 1.995 SEK / Month / user',
    value: 'silver',
  },
  {
    label: 'Gold 2.995 SEK / Month / user',
    value: 'gold',
  },
];

const subscriptionLevelsBuyers = [
  {
    label: 'Silver 1.995 SEK / Month / user',
    value: 'silver',
  },
  {
    label: 'Gold 3.995 SEK / Month / user',
    value: 'gold',
  },
];

const getDefaultValues = (usersCount) => ({
  subscription_level: 'silver',
  number_of_users: usersCount,
  promo_code: '',
});

export const SubscribeForm = ({handleClose, handleSuccess, open, company = null }) => {
  const { user } = useAuth();
  const [ numberOfUsers, setNumberOfUsers ] = useState('');
  const companyID = company?.company_id ? company.company_id : user.Company.company_id;
  const companyType = company?.Type ? company.Type : user.Company.Type;
  const subscriptionLevels = companyType === 'buyer' ? subscriptionLevelsBuyers : subscriptionLevelsSuppliers;
  const {
    //@ts-ignore
    data: { count: usersCount },
  } = useCompanyUsersCount(companyID);

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: getDefaultValues(usersCount),
    resolver: useYupValidationResolver(subscriptionSchema),
  });

  const onSubmit = async (formData) => {
    methods.setError(null);
    
    if (formData.number_of_users < usersCount) {
      methods.setError('users', { type: 'custom', message: `Can not be lower than number of active users (currently ${usersCount})` });
    } else {
      try {
        subscribe({...formData}, { onSuccess });
      } catch (error) {
        methods.setError('promo_code', { type: 'custom', message: error.message });
      }
    }
  };

  const onClose = () => {
    methods.reset({ ...getDefaultValues(usersCount) });
    setNumberOfUsers(usersCount)
    handleClose();
  };

  const onSuccess = () => {
    // handleSuccess();
    refreshPage();
    // onClose();
  };

  const refreshPage = () => {
    window.location.reload();
  }

  const handleNumberOfUsersChange = (value) => {
    setNumberOfUsers(value);
    methods.setValue("number_of_users", value);
  }

  const { mutate: subscribe } = useSubscribe(+companyID, +user.user_id);

  useEffect(() => {
    handleNumberOfUsersChange(usersCount)
  }, [usersCount])  
  
  return (
    <Modal title="Upgrade your subscription" handleClose={onClose} open={open}>
      <Stack>
        <Stack
          gap={1.5}
          sx={{
            a: {
              color: 'inherit',
              fontWeight: 700,
              textTransform: 'none',
            },
          }}
        >
          <Typography variant="body2" sx={{ mb: 3, fontWeight: 400 }}>
            By upgrading to a paid subscription, you get access to more features and more information. Read more about our subscription levels <a href={companyType === 'buyer'? EXTERNAL.PRICING_BUYERS : EXTERNAL.PRICING_SUPPLIERS} target='_blank' rel="noreferrer" >here</a>
          </Typography>
        </Stack>

        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack>
            <Select defaultValue="silver" placeholder="Please select" required fullWidth name="subscription_level" label="Subscription level" options={subscriptionLevels} sx={{ mb: 2 }} />
            <TextField
              name="number_of_users"
              label="Number of users"
              placeholder="Please enter number of users"
              required={true}
              type="text"
              value={numberOfUsers}
              onChange = {(e) => { handleNumberOfUsersChange(e.target.value); }}
              sx={{ mb: 2 }}
              fullWidth
              error={Boolean(methods?.formState?.errors?.users)}
              helperText={methods?.formState?.errors?.users?.message.toString()}
            />
            <TextField
              name="promo_code"
              label="Promotion code (optional)"
              placeholder="Please enter your promotion code"
              required={false}
              type="text"
              sx={{ mb: 2 }}
              fullWidth
              error={Boolean(methods?.formState?.errors?.promo_code)}
              helperText={methods?.formState?.errors?.promo_code?.message.toString()}
            />

            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2, mb: 0.5 }}
            >
              Upgrade your subscription
            </Button>

            <Stack
              gap={1.5}
              sx={{
                a: {
                  color: 'inherit',
                  fontWeight: 700,
                  textTransform: 'none',
                },
              }}
            >
              <Typography variant="body2" sx={{ mt: 2, fontWeight: 400 }}>
                All subscriptions are invoiced annually via E-mail invoice.
                By continuing you accept our <a href={EXTERNAL.TERMS_OF_SERVICE} target='_blank' rel="noreferrer">terms and conditions</a>.
              </Typography>
            </Stack>
          </Stack>
        </FormProvider>
      </Stack>    
    </Modal>
  );
};
