import React, { useEffect } from 'react';

import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { ImageAvatar } from 'components/Avatar';
import { ListCard } from 'components/Card';
import { Status as StatusComponent } from 'components/Status';

import { isoToDateString } from 'utils/dateFormatter';

import { useCompaniesCount } from '../api/getCompaniesCount';
import { useFilesCount } from '../api/getFilesCount';

import { useAuth } from 'lib/auth';
import { paths } from 'routes';

export const ListItem = ({
  dps_id,
  dps_name,
  ref_number,
  Status,
  start_date,
  end_date,
  MainBuyer,
  value,
}) => {
  const { account } = useAuth();
  const {
    //@ts-ignore
    data: { count: buyersCount },
  } = useCompaniesCount(dps_id, 'buyer');
  // const {
  //   //@ts-ignore
  //   data: { count: suppliersCount },
  // } = useCompaniesCount(dps_id, 'supplier');
  const {
    //@ts-ignore
    data: { count: filesCount },
  } = useFilesCount(dps_id);

  return (
    <ListCard>
      <ImageAvatar img={MainBuyer?.square_logo_url || ''} name={dps_name} />
      <Stack flex={{ md: 2, xl: 4 }} sx={{pr:1}} justifyContent="center">
        <Link
          component={RouterLink}
          to={`${paths.dpsDetailsView(dps_id)}`}
          color="inherit"
        >
          <Typography variant="body1" fontWeight={700}>
            {dps_name}
          </Typography>
        </Link>
        <Stack direction="row" gap={1}>
          <Typography variant="caption" fontWeight={600}>
            {ref_number}
          </Typography>
          <Divider
            orientation="vertical"
            sx={{ borderColor: 'grey.500', height: 15, borderWidth: '1px' }}
          />
          <Typography variant="caption" color="text.primary" fontWeight={700}>
            <Link
              component={RouterLink}
              to={`/app/${MainBuyer?.Type}s/${MainBuyer?.company_id.toString()}`}
              color="inherit"
            >
              {MainBuyer?.Name}
            </Link> 
          </Typography>
        </Stack>
      </Stack>
      <Stack justifyContent="center" sx={{pr:1, width: 60}}>
        <Typography variant="caption" fontWeight={700}>
          Buyers
        </Typography>
        <Link
          component={RouterLink}
          to={`${paths.dpsDetailsView(dps_id)}?tab=buyers`}
          color="inherit"
        >
          <Typography variant="body2" fontWeight={700}>
            {buyersCount}
          </Typography>
        </Link>
      </Stack>
      <Stack justifyContent="center" sx={{pr:1, width: 60}}>
        <Typography variant="caption" fontWeight={700}>
        Files
        </Typography>
        <Link
          component={RouterLink}
          to={`${paths.dpsDetailsView(dps_id)}?tab=files`}
          color="inherit"
        >
          <Typography variant="body2" fontWeight={700}>
            {filesCount}
          </Typography>
        </Link>
      </Stack>
      <Stack justifyContent="center" sx={{pr:1, width: 100}}>
        <Typography variant="caption" fontWeight={700}>
          Value
        </Typography>
          <Typography variant="body2" fontWeight={700}>
            {`${value.length > 0 ? value : 0} MSEK`}
          </Typography>
      </Stack>
      <Stack justifyContent="center" sx={{pr:account.super_admin ? 1 : 0, width: 180}}>
        <Typography variant="caption" fontWeight={700}>
          Active Period
        </Typography>
            
        <Typography variant="body2" fontWeight={700}>
          {isoToDateString(start_date, false)} - {isoToDateString(end_date, false)}
        </Typography>
      </Stack>
      {account.super_admin && (
        <StatusComponent status={Status} />
      )}
    </ListCard>
  );
};
