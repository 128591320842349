import React from 'react';

import { Box, SpeedDial, SpeedDialAction, Stack, Typography, Link } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';

import { ImageAvatar } from 'components/Avatar';
import { ListCard } from 'components/Card';
import { IconWrapper } from 'components/Icon';
import { useAuth } from 'lib/auth';
import { paths } from 'routes';
import { useFrameworkAgreementsSupplierFilesCount } from '../api/getFrameworkAgreementsSupplierFilesCount';
import { Status as StatusComponent } from 'components/Status';
import { ReactComponent as RegisteredIcon } from 'assets/Registered.svg';

export const SupplierListItem = ({
  Name: name,
  Status: status,
  VAT: vatNumber,
  markForDelete,
  fa_id,
  fac_id,
  square_logo_url,
  show_files = true,
  company_id: supplierID,
  is_shadow,
}) => {
  const { account } = useAuth();
  const {
    //@ts-ignore
    data: { count: filesCount },
  } = useFrameworkAgreementsSupplierFilesCount(fa_id, fac_id);

  return (
    <ListCard>
      <Box display="flex" alignItems="center" flex={account.super_admin ? 5 : 5.6} gap={1.5}>
        <ImageAvatar img={square_logo_url || ''} name={name} />
        <Typography
          variant="body1"
          fontWeight={700}
          fontSize="1rem"
        >
          <Stack flex={{ md: 2, lg: 4 }} justifyContent="center">
            <Link
              component={RouterLink}
              to={`/app/suppliers/${supplierID.toString()}`}
              color="inherit"
            >
              <Stack direction={'row'} gap={1}>
                <Typography variant="body1" fontWeight={700}>
                  {name}
                </Typography>
                {account.super_admin && !is_shadow && (
                  <RegisteredIcon width={10}/>
                )}
              </Stack>
            </Link>
            <Stack direction="row" gap={1}>
              <Typography variant="caption" fontWeight={600}>
                {vatNumber}
              </Typography>
            </Stack>
          </Stack>
        </Typography>
      </Box>
      {show_files && (
        <Stack justifyContent="center" flex={account.super_admin ? 0.5 : 0.4}>
          <Typography variant="caption" fontWeight={700} color="text.secondary">
            Files
          </Typography>
          <Link
            component={RouterLink}
            to={`${paths.frameworkAgreementSuppliersFiles(fa_id, fac_id)}`}
            color="inherit"
          >
            <Typography variant="body2" fontWeight={700}>
              {filesCount}
            </Typography>
          </Link>
        </Stack>
      )}

      {account.super_admin && (
        <Box flex={0.5} position="relative" sx={{"alignSelf": "center"}}>
          <StatusComponent status={status} />
          <SpeedDial
            direction="left"
            ariaLabel="Buyer actions"
            icon={
              <IconWrapper
                icon="elipsisVertical"
                sx={({ palette }) => ({
                  svg: { stroke: palette.grey[500], color: palette.grey[500] },
                })}
              />
            }
            sx={({ palette }) => ({
              '.MuiSpeedDial-actions': { bgcolor: 'white', borderRadius: 1 },
              '.MuiSpeedDial-actionsClosed': { bgcolor: 'transparent' },
              '& > button': {
                boxShadow: 'none',
                bgcolor: palette.background.paper,
                width: 36,
                height: 36,
              },
              '& > button:hover': { bgcolor: palette.background.paper },
              position: 'absolute',
              right: 0,
              top: 'calc(50% - 28px)',
              zIndex: 1,
            })}
          >
            <SpeedDialAction
              tooltipTitle="Delete"
              onClick={markForDelete}
              sx={({ palette }) => ({
                bgcolor: palette.grey[50],
                '&:hover': { bgcolor: palette.grey[200] },
              })}
              icon={
                <IconWrapper
                  icon="trash"
                  sx={({ palette }) => ({
                    svg: {
                      width: 20,
                      height: 20,
                      stroke: palette.grey[500],
                      color: palette.grey[500],
                    },
                  })}
                />
              }
            />
          </SpeedDial>
        </Box>
      )}
    </ListCard>
  );
};
