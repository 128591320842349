import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { useGetRelatedTenders } from '../api/getRelatedTenders';

import { RelatedTendersListItem } from './RelatedTendersListItem';
import { Pagination } from 'components/Pagination';
import { Search } from 'components/Search';
import { useDebouncedCallback } from 'use-debounce';
import { NotFound } from 'components/NotFound/NotFound';
import { useAuth } from 'lib/auth';
import { Loader } from 'components/Loader';
import { CheckboxFilter } from 'components/Filter/Inputs/CheckboxFilter';
import { PremiumBanner } from '../../subscriptions/components/PremiumBanner';

export const TendersTab = ({ currentTab, activeCount, expiredCount, companyType }) => {
  const tendersTabName = companyType === "buyer" ? "tenders" : "bids";
  const { account, user } = useAuth();
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    includeInactive: false,
    includeExpired: false,
    sortBy: 'dateCreated',
  });
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null);

  const labelName = companyType === "buyer" ? "Tenders" : "Bids";
  const labelNameSingular = companyType === "buyer" ? "Tender" : "Bid";

  const {
    data: { tenders, total },
    refetch: refetchTenders,
    isFetching,
  } = useGetRelatedTenders(id, {
    search,
    ...filters,
    page: page - 1,
    queryOptions: {
      onSettled: () => {
        clearTimeout(timer);
        setTimeout(() => setLoading(false), 1000);
      },
      enabled: currentTab === tendersTabName
    },
  });

  const handlePageChange = (_, page) => setPage(page);
  const handleFilterUpdate = ({ target: { name, checked } }) => {
    setFilters((filters) => {
      return { ...filters, [name]: checked };
    });
  };
  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    debouncedSearchChange(search);
  };

  useTabReset();
  useRefetchTenders();

  const additionalSilver = activeCount - total;
  const additionalGold = additionalSilver + expiredCount;
  let messageSilver = `There are ${additionalSilver} more ${labelName} available for Silver`;
  if (additionalSilver === 1) {
    messageSilver = `There is ${additionalSilver} more ${labelNameSingular} available for Silver`
  }

  let messageGold = `There are ${expiredCount} more ${labelName} available for Gold members`;
  if (additionalGold === 1) {
    messageGold = `There is ${expiredCount} more ${labelNameSingular} available for Gold members`
  }

  useEffect(() => {
    if (isFetching) {
      const timeoutId = setTimeout(() => setLoading(true), 500);
      setTimer(timeoutId);
    } else {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isFetching]);

  if (currentTab !== tendersTabName) return null;

  return (
    <>
      <Stack gap={2} height={1}>
        {!account.super_admin && (
          <>
          {(!user.Company.is_premium || user.Company.subscription_level === 'free') && (
            <PremiumBanner sx={{mt:-2, ml: -4, width: { xs: 768, lg: 900, xl: 1200 }}} message={`${messageSilver} and ${additionalGold} more for Gold members. Click here for more information`} handleSuccess={refetchTenders} currentLevel={user.Company.subscription_level} />
          )}

          {(user.Company.is_premium && user.Company.subscription_level !== 'gold') && (
            <PremiumBanner message={`${messageGold}. Click here for more information`} handleSuccess={refetchTenders} currentLevel={user.Company.subscription_level} />
          )}
          </>
        )}
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} width={1}>
          <Search handleSearchChange={handleSearchChange} sx={{minWidth:200, width: 270}} />
          <Stack direction="row" gap={2} sx={{ flex: '1' }}>
            <CheckboxFilter
              adminOnly
              name="includeInactive"
              onChange={handleFilterUpdate}
              checked={filters.includeInactive}
              label="Inactive"
            />
            <CheckboxFilter
              name="includeExpired"
              onChange={handleFilterUpdate}
              checked={filters.includeExpired}
              label={`Expired (${expiredCount})`}
              subscriptionTypes={['gold']}
              tooltip="Available only for Gold members"
            />
          </Stack>
        </Stack>
        <Divider />

        {isFetching && !loading ? 
        (
          <></>
        ) :
        (!isFetching || (isFetching && loading)) ?
        (
          <>
          {loading && (
            <Loader />
          )}

          {!loading && (
            <>
            <Stack gap={1} flex={1}>
              {(!tenders?.length || tenders?.length === 0) && (
                <NotFound
                    title={`No related ${labelName} found`}
                    caption={
                    search
                        ? 'Please try another search term'
                        : ''
                    }
                    wrapperSx={{ mt: 19 }}
                />
              )}

              {tenders.map((tender) => (
                <RelatedTendersListItem
                  key={tender.tender_id}
                  companyID={id}
                  companyType={companyType}
                  {...tender}
                />
              ))}
            </Stack>

            <Stack direction="row" justifyContent="center">
              <Pagination page={page} handlePageChange={handlePageChange} itemCount={total} />
            </Stack>
        </>
          )}
        </>
        ) :
        (
          <></>
        )}
      </Stack>
    </>
  );

   function useRefetchTenders() {
    useEffect(() => {
      refetchTenders();
    }, [page, filters, search]);
  }

  function useTabReset() {
    useEffect(() => {
      if (currentTab !== tendersTabName) {
        setPage(1);
        setSearch('');
        handleFilterUpdate({target: {name: "includeInactive", checked: false}});
        handleFilterUpdate({target: {name: "includeExpired", checked: false}});
      }
    }, [currentTab]);
  }
};
