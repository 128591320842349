import React from 'react';

import { Link, Stack, Typography, Divider } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { ImageAvatar } from 'components/Avatar';
import { ListCard } from 'components/Card';
import { Status as StatusComponent } from 'components/Status';
import { isoToDateString } from 'utils/dateFormatter';
import { paths } from 'routes';
import { useFilesCount } from '../../dps/api/getFilesCount';

import { useAuth } from 'lib/auth';

export const RelatedDPSListItem = ({ companyID, dps_name: name, Status: status, dps_id: id, ref_number, start_date, end_date, MainBuyer, value }) => {  
    const { account } = useAuth();
    const {
      //@ts-ignore
      data: { count: generalFilesCount },
    } = useFilesCount(id);
    return (
      <ListCard>
        <ImageAvatar img={MainBuyer?.square_logo_url || ''} name={name} />
        <Stack flex={7} justifyContent="center">
          <Link
            component={RouterLink}
            to={`/app/dps/${id.toString()}`}
            color="inherit"
          >
            <Typography variant="body1" fontWeight={700}>
              {name}
            </Typography>
          </Link>
          <Stack direction="row" gap={1}>
            <Typography variant="caption" fontWeight={600}>
              {ref_number}
            </Typography>
            <Divider
              orientation="vertical"
              sx={{ borderColor: 'grey.500', height: 15, borderWidth: '1px' }}
            />
            <Typography variant="caption" color="text.primary" fontWeight={700}>
              <Link
                component={RouterLink}
                to={`/app/${MainBuyer?.Type}s/${MainBuyer?.company_id.toString()}`}
                color="inherit"
              >
                {MainBuyer?.Name}
              </Link> 
            </Typography>
          </Stack>
        </Stack>
        <Stack justifyContent="center" sx={{pr:1, width: 100}}>
          <Typography variant="caption" fontWeight={700}>
            Value
          </Typography>
          <Typography variant="body2" fontWeight={700}>
            {`${value.length > 0 ? value : 0} MSEK`}
          </Typography>
        </Stack>
        <Stack justifyContent="center" sx={{pr:1, width: 60}}>
          <Typography variant="caption" fontWeight={700}>
            Files
          </Typography>
          <Link
            component={RouterLink}
            to={`${paths.dpsDetailsView(id)}?tab=files`}
            color="inherit"
          >
            <Typography variant="body2" fontWeight={700}>
              {generalFilesCount}
            </Typography>
          </Link>
        </Stack>
        <Stack justifyContent="center" sx={{pr:account.super_admin ? 1 : 0, width: 180}}>
          <Typography variant="caption" fontWeight={700}>
            Active Period
          </Typography>
        
          <Typography variant="body2" fontWeight={700}>
            {isoToDateString(start_date, false)} - {isoToDateString(end_date, false)}
          </Typography>
        </Stack>

        {account.super_admin && (
          <StatusComponent status={status} />
        )}
      </ListCard>
    );
  };
