import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';


import { DeleteConfirmationModal } from '../components/DeleteConfirmationModal';
import { Pagination } from 'components/Pagination';
import { Search } from 'components/Search';
import { useDebouncedCallback } from 'use-debounce';
import { NotFound } from 'components/NotFound/NotFound';
import { useAuth } from 'lib/auth';

import { UserListItem } from './UsersListItem';

import { useGetCompanyUsers } from '../api/getCompanyUsers';
import { useCompanyUsersCount } from '../api/getCompanyUsersCount';
import { useRemoveUser } from '../api/removeUser';
import { useHandleUserRequest } from '../api/handleUserRequest';
import { CheckboxFilter } from 'components/Filter/Inputs/CheckboxFilter';

import { InviteUserModal } from '../components/InviteUserModal';
import { Loader } from 'components/Loader';

const usersTabName = 'contacts';

export const UsersTab = ({ currentTab, usersCount }) => {
  const { account, user } = useAuth();
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [markedForDelete, setMarkedForDelete] = useState(null);
  const [markedForAccept, setMarkedForAccept] = useState(null);
  const [markedForReject, setMarkedForReject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null);
  const [filters, setFilters] = useState({
    includeInactive: false,
  });

  const {
    data: { users, total },
    refetch: refetchUsers,
    isFetching,
  } = useGetCompanyUsers(id, {
    search,
    ...filters,
    page: page - 1,
    queryOptions: {
      onSettled: () => {
        clearTimeout(timer);
        setTimeout(() => setLoading(false), 1000);
      },
      enabled: currentTab === usersTabName && usersCount > 0
    },
  });

  const {
    //@ts-ignore
    refetch: refetchUsersCount,
  } = useCompanyUsersCount(id);

  const handlePageChange = (_, page) => setPage(page);
  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    debouncedSearchChange(search);
  };

  const onUserRemoval = () => {
    refetchUsers();
    setMarkedForDelete(null);
    refetchUsersCount();
  };

  const onUserAcceptance = () => {
    refetchUsers();
    setMarkedForAccept(null);
    refetchUsersCount();
  };

  const onUserRejecting = () => {
    refetchUsers();
    setMarkedForReject(null);
    refetchUsersCount();
  };

  const handleFilterUpdate = ({ target: { name, checked } }) => {
    setFilters((filters) => {
      return { ...filters, [name]: checked };
    });
  };

  const { mutateAsync: removeUser } = useRemoveUser(onUserRemoval);
  const { mutateAsync: handleAcceptRequest } = useHandleUserRequest(onUserAcceptance, 'Contact successfully accepted');
  const { mutateAsync: handleRejectRequest } = useHandleUserRequest(onUserRejecting, 'Contact successfully rejected');

  const companyAdmin = (user.role === 'company_admin' && user.Company.company_id === parseInt(id)); 

  useTabReset();
  useRefetch();

  useEffect(() => {
    if (isFetching) {
      const timeoutId = setTimeout(() => setLoading(true), 500);
      setTimer(timeoutId);
    } else {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isFetching]);

  if (currentTab !== usersTabName) return null;

  return (
    <>
      {(account.super_admin || companyAdmin) && (
        <>
        <DeleteConfirmationModal
          title={'Remove Contact'}
          markedForDelete={markedForDelete}
          mutateAsync={() => removeUser({ userID: markedForDelete?.itemId })
          }
        >
          <Typography variant="body1" fontWeight={500}>
            Please confirm the removal of the contact{' '}
            <span style={{ fontWeight: 'bold' }}>{markedForDelete?.name}.</span>
          </Typography>
        </DeleteConfirmationModal>
        <DeleteConfirmationModal
          title={'Accept Contact'}
          markedForDelete={markedForAccept}
          mutateAsync={() => handleAcceptRequest({ companyID:id, userID: markedForAccept?.itemId, action: "accept"})
          }
        >
          <Typography variant="body1" fontWeight={500}>
            Are you sure you want to accept contact{' '}
            <span style={{ fontWeight: 'bold' }}>{markedForAccept?.name}?</span>
          </Typography>
        </DeleteConfirmationModal>
        <DeleteConfirmationModal
          title={'Accept Contact'}
          markedForDelete={markedForReject}
          mutateAsync={() => handleRejectRequest({ companyID:id, userID: markedForReject?.itemId, action: "reject"})
          }
        >
          <Typography variant="body1" fontWeight={500}>
            Are you sure you want to reject contact{' '}
            <span style={{ fontWeight: 'bold' }}>{markedForReject?.name}?</span>
          </Typography>
        </DeleteConfirmationModal>
        </>
      )}

      <Stack gap={2} height={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} width={1}>
          <Search handleSearchChange={handleSearchChange} sx={{minWidth: 200, width: 270}} />
          <Stack sx={{ flex: '1' }}>
            <CheckboxFilter
              companyAdmin
              companyID={id}
              name="includeInactive"
              onChange={handleFilterUpdate}
              checked={filters.includeInactive}
              label="Inactive"
            />
          </Stack>
          {/* {account.super_admin && (
            <FormGroup sx={{ flex: '1 1 300px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={includeInactive}
                    onChange={handleIncludeInactiveChange}
                  />
                }
                label="Include inactive"
              />
            </FormGroup>
          )} */}
          {(account.super_admin || companyAdmin) && (
            <Box sx={{minWidth: 150, width: 200}}>
              <InviteUserModal handleSuccess={refetchUsers} />
            </Box>
          )}
        </Stack>

        <Divider />

        {isFetching && !loading ? 
        (
          <></>
        ) :
        (!isFetching || (isFetching && loading)) ?
        (
          <>
          {loading && (
            <Loader />
          )}

          {!loading && (
            <>
            <Stack gap={1} flex={1}>
              {users?.length === 0 && (
                <NotFound
                  title="No related contacts found"
                  caption={
                    search
                      ? 'Please try another search term'
                      : account.super_admin ? 'Click the "Invite Contact" button to begin' : ''
                  }
                  wrapperSx={{ mt: 19 }}
                />
              )}
              {users.map((user) => (
                <UserListItem
                  key={user.user_id}
                  markForDelete={() => setMarkedForDelete({ name: `${user.first_name} ${user.last_name}`, itemId: user.user_id })}
                  markForAccept={() => setMarkedForAccept({ name: `${user.first_name} ${user.last_name}`, itemId: user.user_id })}
                  markForReject={() => setMarkedForReject({ name: `${user.first_name} ${user.last_name}`, itemId: user.user_id })}
                  companyID={parseInt(id)}
                  {...user}
                />
              ))}
            </Stack>

            <Stack direction="row" justifyContent="center">
              <Pagination page={page} handlePageChange={handlePageChange} itemCount={total} />
            </Stack>
            </>
          )}
        </>
        ) :
        (
          <></>
        )}
      </Stack>
    </>
  );

  function useRefetch() {
    useEffect(() => {
      refetchUsers();
    }, [page, filters, search]);
  }

  function useTabReset() {
    useEffect(() => {
      if (currentTab !== usersTabName) {
        setPage(1);
        setSearch('');
        setFilters({ ...filters, "includeInactive": false })
      }
    }, [currentTab]);
  }
};
