import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { AccordionButton } from 'components/Button';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { ContentLayout } from 'components/Layout';

import { useQueryParams } from 'hook/useQueryParams';

import { paths } from 'routes';

import { useGetCompanyById } from '../api/getCompanyById';
import { DetailViewTabList } from 'components/TabList';
import { FrameworkAgreementsTab } from '../components/FrameworkAgreementsTab';
import { TendersTab } from '../components/TendersTab';
import { DPSTab } from '../components/DPSTab';
import { CompaniesTab } from '../components/CompaniesTab';
import { StatusToggleButton } from '../components/StatusToggleButton';
import { useAuth } from 'lib/auth';

import { useRelatedCompaniesCount } from '../api/getRelatedCompaniesCount';
import { useCompanyFrameworkAgreementsCount } from '../api/getCompanyFrameworkAgreementsCount';
import { SquareImage } from '../components/SquareImage';
import { LogoUpload } from '../components/LogoUpload';
import { useCompanyUsersCount } from '../api/getCompanyUsersCount';

import { UsersTab } from '../components/UsersTab';
import { BannerImage } from '../components';
import { Subscription } from '../components/Subscription';

import { useCompanyTendersCount } from '../api/getRelatedTendersCount';
import { useCompanyDPSCount } from '../api/getRelatedDPSCount';
import { Loader } from 'components/Loader';

export const DetailsView = ({ companyType }) => {
  const queryParams = useQueryParams();
  const { account, user } = useAuth();
  let { id } = useParams();
  const [tab, setTab] = useState(queryParams?.tab || 'agreements');
  const [ loading, setLoading ] = useState(false);
  const [timer, setTimer] = useState(null);

  const { data: company, refetch: refetchCompany, isLoading: companyLoading, isFetched: isFetched9 } = useGetCompanyById(id);

  const {
    //@ts-ignore
    data: { count: relatedCompaniesCount, isLoading: relatedCompaniesCountLoading, isFetched: isFetched1 },
  } = useRelatedCompaniesCount(id, companyType);
  const {
    //@ts-ignore
    data: { count: activeFACount, isLoading: activeFACountLoading, isFetched: isFetched2 },
  } = useCompanyFrameworkAgreementsCount(id, {expired: false});
  const {
    //@ts-ignore
    data: { count: expiredFACount, isLoading: expiredFACountLoading, isFetched: isFetched3 },
  } = useCompanyFrameworkAgreementsCount(id, {expired: true});
  const {
    //@ts-ignore
    data: { count: usersCount, isLoading: usersCountLoading, isFetched: isFetched4 },
  } = useCompanyUsersCount(id);  
  const {
    //@ts-ignore
    data: { count: activeTendersCount, isLoading: activeTendersCountLoading, isFetched: isFetched5 },
  } = useCompanyTendersCount(id, {expired: false});
  const {
    //@ts-ignore
    data: { count: expiredTendersCount, isLoading: expiredTendersCountLoading, isFetched: isFetched6 },
  } = useCompanyTendersCount(id, {expired: true});
  const {
    //@ts-ignore
    data: { count: activeDPSCount, isLoading: activeDPSCountLoading, isFetched: isFetched7 },
  } = useCompanyDPSCount(id, {
    expired: false,
    queryOptions: {
      enabled: companyType === 'buyer',
    },
  });
  const {
    //@ts-ignore
    data: { count: expiredDPSCount, isLoading: expiredDPSCountLoading, isFetched: isFetched8 },
  } = useCompanyDPSCount(id, {
    expired: true,
    queryOptions: {
      enabled: companyType === 'buyer',
    },
  });

  const labelName = companyType === 'buyer' ? 'Suppliers' : 'Customers';
  const tenderLabelName = companyType === 'buyer' ? 'Tenders' : 'Bids';
  const breadCrumbslabelName = companyType === 'buyer' ? 'Buyers' : 'Suppliers';

  const switchTab = (_, newTab) => setTab(newTab);

  const isLoading = companyLoading || relatedCompaniesCountLoading || activeFACountLoading || expiredFACountLoading || usersCountLoading || activeTendersCountLoading || expiredTendersCountLoading || activeDPSCountLoading || expiredDPSCountLoading;
  const isFetched = isFetched1 || isFetched2 || isFetched3 || isFetched4 || isFetched5 || isFetched6 || isFetched7 || isFetched8 || isFetched9;

  useEffect(() => {  
    if (isLoading) {
      const timeoutId = setTimeout(() => setLoading(true), 500);
      setTimer(timeoutId);
    } else {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isLoading]);

  if (isFetched) {    
    if (loading) {      
      setTimeout(() => setLoading(false), 1000);
    }
  }

  return (
    <ContentLayout>
      {isLoading && !loading ? (
        <></>
      )
      : (
        loading ? (
          <Loader style={{marginTop: "40vh"}}/>
        )
        : (
          <Stack height="100%" width={1}>
            <BreadCrumbs
              items={[
                { name: '',
                  to: companyType === 'buyer' ? paths.buyers : paths.suppliers,
                },
                {
                  name: breadCrumbslabelName,
                  to: companyType === 'buyer' ? paths.buyers : paths.suppliers,
                },
                { name: company?.Name ?? 'Details View' },
              ]}
            />
            <BannerImage banner={company?.background_image_url} bannerChangable refetch={refetchCompany} />

            <Stack direction="row">
              <SquareImage image={company?.square_logo_url || ''} />

              <Stack flex={1}>
                <Box
                  px={4}
                  height={70}
                  sx={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Stack position="relative" flexDirection="row">
                    <Stack>
                      {account.super_admin && (
                        <Divider
                          sx={({ palette }) => ({
                            height: 5,
                            width: 30,
                            bgcolor:
                              company?.Status === 'active'
                                ? palette.success.light
                                : palette.error.light,
                            borderRadius: 1,
                          })}
                        />
                      )}
                      <Typography variant="h3">{`${company?.Name} (${company?.VAT})` || ''}</Typography>
                    </Stack>
                  </Stack>

                  {(account.super_admin || user.Company?.company_id.toString() === id) && (
                    <AccordionButton label="Actions" sx={{ width: 130, zIndex: 10, top:"20px" }}>
                      <Stack gap={0.5}>
                        <>
                          {account.super_admin && (
                            <StatusToggleButton refetch={refetchCompany} status={company?.Status} />
                          )}
                          <LogoUpload />
                          <Subscription currentLevel={company?.subscription_level} isSuperAdmin={account.super_admin} company={company}/>
                        </>
                      </Stack>
                    </AccordionButton>
                  )}
                </Box>
              </Stack>
            </Stack>

            <DetailViewTabList
              value={tab}
              onChange={switchTab}
              tabs={[
                { label: 'Agreements', count: activeFACount + expiredFACount },
                { label: tenderLabelName, count: activeTendersCount + expiredTendersCount },
                ...(companyType === 'buyer' ? [{ label: 'DPS', count: activeDPSCount + expiredDPSCount }] : []),
                { label: labelName, count: relatedCompaniesCount },
                ...((account.super_admin || usersCount > 0) ? [{ label: 'Contacts', count: usersCount }] : []),
              ]}
            />

            <Stack
              px={4}
              py={2}
              bgcolor="background.paper"
              borderTop="1px solid"
              borderColor="border.main"
              gap={1}
              height="100%"
            >
              <FrameworkAgreementsTab currentTab={tab} activeCount={activeFACount} expiredCount={expiredFACount} companyType={companyType} />

              <TendersTab currentTab={tab} activeCount={activeTendersCount} expiredCount={expiredTendersCount} companyType={companyType} />

              {companyType === 'buyer' && (
                <DPSTab currentTab={tab} activeCount={activeDPSCount} expiredCount={expiredDPSCount}  />
              )}
              <CompaniesTab currentTab={tab} companyType={companyType} />

              <UsersTab currentTab={tab} usersCount={usersCount} />
            </Stack>
          </Stack>
        )
      )}
    </ContentLayout>
  );
};
