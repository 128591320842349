import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { AccordionButton } from 'components/Button';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { ContentLayout } from 'components/Layout';

import { paths } from 'routes';

import { useTender } from '../api/getTenderById';
import { DetailViewTabList } from '../components/TabList';
import { DetailsTab } from '../components/DetailsTab';
import { BuyersTab } from '../components/BuyersTab';
import { SuppliersTab } from '../components/SuppliersTab';
import { EditForm } from '../components/EditForm';
import { useTenderCompanyCount } from '../api/getTendersCompanyCount';
import { useTenderFilesCount } from '../api/getTendersFilesCount';
import { useTenderSuppliers } from '../api/getTenderSuppliers';
import { useTendersSupplierFilesCountQueries } from '../api/getTendersSupplierFilesCount';
import { StatusToggleButton } from '../components/StatusToggleButton';
import { useAuth } from 'lib/auth';
import { FilesTab } from '../components/FilesTab';
import { PremiumBanner } from '../../subscriptions/components/PremiumBanner';
import { Loader } from 'components/Loader';

export const DetailsView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const currentTab = queryParams.get('tab') || 'details';
  const [ loading, setLoading ] = useState(false);
  const { account, user } = useAuth();
  let { id } = useParams();
  const [tab, setTab] = useState(currentTab);
  const [timer, setTimer] = useState(null);

  const { data: tender, refetch: refetchTender, error, isError,  isLoading: tenderLoading, isFetched: isFetched1 } =
    useTender(id);
  const {
    //@ts-ignore
    data: { count: buyersCount, isLoading: buyersCountLoading, isFetched: isFetched2 },
    refetch: refetchBuyersCount,
  } = useTenderCompanyCount(id, 'buyer');
  const {
    //@ts-ignore
    data: { count: suppliersCount, isLoading: suppliersCountLoading, isFetched: isFetched3 },
  } = useTenderCompanyCount(id, 'supplier');
  const {
    //@ts-ignore
    data: { count: filesCount, isLoading: filesCountLoading, isFetched: isFetched4 },
  } = useTenderFilesCount(id);

  const {
    data: { suppliers },
    isLoading: isLoadingSuppliers,
    isFetched: isFetched5,
    refetch: refetchSuppliersFiles,
  } = useTenderSuppliers(id, {
    includeInactive: account.super_admin ? true : false,
    limit: 200,
    queryKey: [`framework-agreement-${id}-files-suppliers`],
    queryOptions: {
      onSettled: () => {
        clearTimeout(timer);
        setTimeout(() => setLoading(false), 1000);
      },
    },
  });

  const tcIDs = suppliers.map(supplier => supplier.tc_id);
  const filesCountQueries = useTendersSupplierFilesCountQueries(id, tcIDs);
  const suppliersFilesCount = filesCountQueries.reduce((acc, query, index) => {
    if (tcIDs[index] != null) {
      acc[tcIDs[index]] = query.data?.count || 0;
    }

    return acc;
  }, {});
  const isLoadingSuppleirsFilesCount = filesCountQueries.some(query => query.isLoading);
  const allSuppliersFilesFetched = filesCountQueries.every(query => query.isFetched);

  const { suppliersFilesTotal, isSuppliersFilesTotalReady } = allSuppliersFilesFetched ? filesCountQueries.length === 0 ?
  { suppliersFilesTotal: 0, isSuppliersFilesTotalReady: true }
  :
  filesCountQueries.reduce(
    (acc, query, index, array) => {
      const count = query.data?.count || 0;
      acc.suppliersFilesTotal += count;

      if (index === array.length - 1 && allSuppliersFilesFetched) {
        acc.isSuppliersFilesTotalReady = true;
      }

      return acc;
    },
    { suppliersFilesTotal: 0, isSuppliersFilesTotalReady: false }
  ) :
  { suppliersFilesTotal: 0, isSuppliersFilesTotalReady: false };

  const switchTab = (_, newTab) => {
    navigate(`?tab=${newTab}`, { replace: true });
  };

  const isLoading = tenderLoading || buyersCountLoading || suppliersCountLoading || filesCountLoading || isLoadingSuppliers || isLoadingSuppleirsFilesCount;
  const isFetched = isFetched1 || isFetched2 || isFetched3 || isFetched4 || isFetched5 || allSuppliersFilesFetched;

  const totalFiles = isFetched && isSuppliersFilesTotalReady ? filesCount + suppliersFilesTotal : 0; 

  useEffect(() => {  
    if (isLoading) {
      const timeoutId = setTimeout(() => setLoading(true), 500);
      setTimer(timeoutId);
    } else {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isLoading]);

  if (isFetched) {    
    if (loading) {      
      setTimeout(() => setLoading(false), 1000);
    }
  }

  useEffect(() => {
    setTab(currentTab);
  }, [currentTab]);

  if (!loading && isError && error.message === "Object doesn't exist") {
    return <div>{error?.message}</div>;
  }

  return (
    <ContentLayout>
      {isLoading && !loading ? (
        <></>
      )
      : (
        loading ? (
          <Loader style={{marginTop: "40vh"}}/>
        )
        : (
          !loading && isError ? (
            <PremiumBanner message={`This Tender is only available for Premium members. Click here for more information`} handleSuccess={refetchTender} currentLevel={user.Company.subscription_level} />
          ) : (
            <Stack height="100%">
              <BreadCrumbs
                items={[
                  { name: '', to: paths.tenders },
                  { name: 'Tenders', to: paths.tenders },
                  { name: `${tender.MainBuyer.Name}`, to: `${paths.buyersDetailsView(tender.MainBuyer.company_id)}?tab=tenders` },
                  { name: tender?.tender_name ?? 'Details View' },
                ]}
              />
              <Box
                height={50}
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  paddingBottom: 1.5,
                  paddingTop: 1,
                }}
              >
                <Stack pl={4}>
                  <Typography variant="h3">
                    <Stack flex={1} flexDirection={"row"} gap={1}>
                      {tender?.tender_name || ''}
                      {account.super_admin && (
                        <Stack flex={1} sx={{justifyContent: "center"}}>
                          <Box
                            borderRadius="100%"
                            width={8}
                            height={8}
                            border="1px solid"
                            borderColor={tender?.Status === 'active' ? 'success.light' : 'error.light'}
                            bgcolor={tender?.Status === 'active' ? 'success.light' : 'error.light'}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </Typography>
                  <Typography variant='caption' fontWeight={600}>
                    {`${tender.MainBuyer.Name} (${tender.ref_number})`}
                  </Typography>
                </Stack>

                {account.super_admin && (
                  <AccordionButton label="Actions" sx={{ width: 130, zIndex: 10 }}>
                    <Stack gap={0.5}>
                      <EditForm tender={tender} refetch={refetchTender}/>

                      <StatusToggleButton
                        refetch={refetchTender}
                        status={tender?.Status}
                      />
                    </Stack>
                  </AccordionButton>
                )}
              </Box>

              <DetailViewTabList
                value={tab}
                onChange={switchTab}
                tabs={[
                  { label: 'Details' },
                  { label: 'Buyers', count: buyersCount },
                  { label: 'Bidders', count: suppliersCount },
                  { label: 'Files', count: totalFiles },
                ]}
              />

              <Stack
                px={4}
                py={2}
                bgcolor="background.paper"
                borderTop="1px solid"
                borderColor="border.main"
                gap={1}
                height="100%"
              >
                {error && (
                  <PremiumBanner message={`This Tender is only available for Premium members. Click here for more information`} handleSuccess={refetchTender} currentLevel={user.Company.subscription_level} />
                  // <div>{error?.message}</div>
                )}
                {!error && (
                  <>
                {/* {tab === "details" && ( */}
                  <DetailsTab currentTab={tab} tender={tender} />
                {/* )} */}

                {/* {tab === "buyers" && ( */}
                  <BuyersTab
                    currentTab={tab}
                    refetchBuyersCount={refetchBuyersCount}
                  />
                {/* )} */}

                {/* {tab === "suppliers" && ( */}
                  <SuppliersTab currentTab={tab} refetchSuppliersFiles={refetchSuppliersFiles} />
                {/* )} */}

                {/* {tab === "files" && ( */}
                  <FilesTab
                    currentTab={tab}
                    suppliers={suppliers}
                    suppliersFilesCount={suppliersFilesCount}
                    generalFilesCount={filesCount}
                  />
                {/* )} */}
                </>
              )}
              </Stack>
            </Stack>
          )
        )
      )}
    </ContentLayout>
  );
};
