import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';

const getCompanyFrameworkAgreementFilesCount = async (tId, companyID) => {
  const {
    data: { tcID },
  } = await axios.get(`/api/companies/${companyID}/tenders/${tId}`);

  return { tcID };
};


export const useCompanyTcID = (tId, companyID, queryParams = {}) => {
  return useQuery(
    `company-${companyID}-tender-${tId}-tcID`,
    () => getCompanyFrameworkAgreementFilesCount(tId, companyID),
    {
      //@ts-ignore
      select: ({ tcID }) => ({ tcID }),
      initialData: { tcID: 0 },
      ...queryParams,
    }
  );
}